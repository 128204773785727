.learnheader{
    margin-top:145px;
}
.learncontent{
    position: absolute;
    top: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
}
.over-img-text{
    color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}
.over-img-text2{
    color: #FFF;
    text-align: center;
    font-size: 24px;
    width: 75%;
}
.overall-layout{
    padding-bottom: 100px;
}
.carousel{
    margin-top: 100px;
}

@media screen and (min-width:1025px) and (max-width:1280px){
    .learncontent{
        left: 7%;
    }
}
@media screen and (min-width:769px) and (max-width:1024px){
    .learncontent{
        left: 0%;
        top: 33%;
    }
}
@media screen and (min-width:671px) and (max-width:768px){
    .over-img-text{
        font-size:30px;
    }
    .learncontent{
        left: 0%;
        top: 28%;
        gap:10px
    }
    .over-img-text2{
        font-size: 22px;
    }
}
@media screen and (max-width:740px) {
    .over-img-text2{
        width: 100%;
    }
}
@media screen and (min-width:426px) and (max-width:670px) {
    .over-img-text{
        font-size: x-large;
    }
    .over-img-text2{
        font-size: small;
        /* width: 100%; */
    }
    .learncontent{
        left: 0%;
        top: 30%;
        gap:10px
    }
}
@media screen and (max-width:426px) {
    .over-img-text{
        font-size: larger;
    } 
    .over-img-text2{
        font-size: smaller;
    }
    .learncontent{
        left: 0%;
        top: 25%;
        gap:10px
    }
    
}
@media screen and (max-width:376px) {
    .over-img-text{
        font-size: large;
    } 
    .over-img-text2{
        font-size: x-small;
    }
    .learncontent{
        left: 0%;
        top: 25%;
        gap:10px
    }
}
@media screen and (max-width:320px) {
    .over-img-text{
        font-size:medium;
    }
    .learncontent{
        left: 0%;
        top: 25%;
        gap:10px
    }
}