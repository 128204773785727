body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "ClashGroteskBold";
  src: local("ClashGrotesk-Bold"),
   url("./Fonts/OTF/ClashGrotesk-Bold.otf") format("OpenType");
  font-weight: 300;
 }

 @font-face {
  font-family: "ClashGroteskExtalight";
  src: local("ClashGrotesk-Extralight"),
   url("./Fonts/OTF/ClashGrotesk-Extralight.otf") format("OpenType");
  font-weight: lighter;
 }

 @font-face {
  font-family: "ClashGroteskLight";
  src: local("ClashGrotesk-Light"),
   url("./Fonts/OTF/ClashGrotesk-Light.otf") format("OpenType");
  font-weight: light;
 }
 
 @font-face {
  font-family: "ClashGroteskMedium";
  src: local("ClashGrotesk-Medium"),
   url("./Fonts/OTF/ClashGrotesk-Medium.otf") format("OpenType");
  font-weight: medium;
 }

 @font-face {
  font-family: "ClashGroteskRegular";
  src: local("ClashGrotesk-Regular"),
   url("./Fonts/OTF/ClashGrotesk-Regular.otf") format("OpenType");
  font-weight: regular;
 }

 @font-face {
  font-family: "ClashGroteskSemibold";
  src: local("ClashGrotesk-Semibold"),
   url("./Fonts/OTF/ClashGrotesk-Semibold.otf") format("OpenType");
  font-weight: semibold;
 }