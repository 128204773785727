/* Form.css */
form{
  display: contents;
}
.center {
  text-align: center;
  margin-top: 20px;
  gap: 10px;
}

.inputbox {
  position: relative;
  width: 300px;
  padding: 15px 12px;
  border-radius: 4px; 
  border: 1px solid var(--Base-White, #FFF);
}
.inputboxsubmit{
  width: 250px;
  padding: 15px 12px;
  align-items: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputbox input {
  width: 100%;
  /* background: none; */
  border: none;
  background-color: transparent;
  color: white;
  font-size: 20px;
  outline: none;
}

.inputbox span {
  align-items: center;
  position: absolute;
  left: 10px;
  top:35%;
  transform: translateY(-50%);
  color: white;
  pointer-events: none;
  transition: 0.5s;
}

.inputbox input:focus + span,
.inputbox input:valid + span {
  transform: translateY(-212%);
  font-size: 12px;
  color: white;
}

/* Add the following CSS to change border color when label is clicked */
.error-message {
  color: white;
  font-size: 12px;
  margin-top: 5px;
}

.inputbox.clicked input{
  border: none;
}

.invalid input {
  outline: none;
}

.invalid span {
  color: #f00;
}

.inputboxsubmit button {
  background-color: white;
  color: #FF2526;
  padding: 10px 20px;
  width: 100%;
  border-radius: 4px;
}

/* .inputboxsubmit button:hover {
  background-color: #FF2526;
  color: white;
} */

.d-inline-flex {
  display: inline-flex;
}

@media only screen and (max-width: 768px) {
  .md-d-flow {
    flex-direction: column;
  }
  .inputbox{
    margin-bottom: 20px;
  }
  .center{
    margin-top: 0px;
    gap: 0px;
    justify-content: center;
  }
  .footer{
    gap: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .sm-d-flow {
    flex-direction: column;
  }
}
