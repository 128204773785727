.sublaycol{
    text-align: left;
    width: 100%;
    margin-bottom:10%;
}
.coltitle{
    font-size: 24px;
}
.colitems{
    font-size: 16px;
}
@media screen and (max-width:426px){
    .sublaycol{
        width: 100%;
    }
}