.bodycol{
    display: inline-flex;
    align-items: flex-start;
    gap: 80px;
    padding: 53px 0px 200px 0px ;
  }
  .bodycol-items{
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 20px;
  }
.bodycol-items{
    border: 1px solid rgba(0, 0, 0, 0.20);
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 186px;
}
.bodycol-items:hover{
    background-color: var(--clr-bg);
  overflow: hidden;
  transform: scale(1.08);
  box-shadow: rgba(0, 0, 0, 0.36) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border: 5px solid var(--clr-primary);
}
.itemstitle{
    text-align: right;
}

@media screen and (max-width:768px){
    .bodycol{
        flex-direction: column;
        align-items: center;
    }
    .bodytext-layout{
        height: auto;
    }
}
@media screen and(max-width:820px){
  .bodycol{
    gap:65px;
  }

}