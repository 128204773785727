.layout-white{
    height: 675px; 
    margin-top: 150px;
}
.layout-gray{  
    background-color: #0000000A;
    height: 425px;
 }
.hometext{
    color: #000;
    font-size: 40px; 
    line-height:initial;
    font-weight: 400;
    /* padding: 0px 557px 0px 558px ; */
}
.layout-image{
    margin-top: -490px;
    height: 750px;
    border-radius: 20px;
    width: 82%;
}
.imgs{
    margin-top: 75px;
    margin-bottom: 200px;
}
.image-layout{
    height: 750px;
    border-radius: 20px;
    margin-bottom: 100px;
    margin-top: 100px;
}
.mobile-ads{
    border-radius: 20px;
}

@media screen and (max-width: 320px){
    .image-layout{
        height: 205px;
        margin-bottom: 110px;
    }
    .layout-image{
        margin-top: -110px;
        height: 205px;
        width: 90%;
    }
    .layout-white{
        height: 328px;
    }
    .layout-gray{
        height: 190px;
    }
    .imgs{
        margin-bottom: 150px;
    }
}

@media screen and (max-width:426px) {
    .layout-image{
        width: 93%;
    }
    
}
@media screen and (min-width:321px) and (max-width: 767px){
    .image-layout{
        height: 355px;
        margin-bottom: 125px;
    }
    .layout-image{
        margin-top: -185px;
        height: 355px;

    }
    .layout-white{
        height: 328px;
    }
    .layout-gray{
        height: 250px;
    }
}
@media screen and (min-width:321px) and (max-width:546px){
    .hometext{
        font-size: 36px;
    }
    .layout-image{
        margin-top: -145px;
    }
    .layout-gray{
        height: 310px;
    }
}
@media screen and (max-width:320px) {
    .hometext{
        font-size: 36px;
    }
    
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .layout-image{
        height: 750px;
        width: 90%;
    }
    .image-layout{
        height: 750px;
        width: 99%;
    }
    
}
@media screen and (min-width:1025px) and (max-width:1440px) {
    .layout-image{
        width: 90%;
    }
}