a{
  text-decoration: none;
  color: #FFF;
}
.footer{
    display: flex;
    height: 500px;
    /* padding: 121px 423px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}
.footer-text1{
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    line-height: normal;
}
.footer-text2{
    color: #FFF;
    /* text-align: center;   */
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px 5px;
    display: flex;
    align-items: center;
}
button{
    color: white;
    background: transparent;
    border: none;
}
.letstalk{
    color: #ffffff;
    font-size: 15px;
}
.animated-button {
    transition: transform 0.3s ease-in-out;
  }
  
  .animated-button:hover {
    transform: scale(2.1); 
  }
  .arrow-icon {
    transition: transform 0.3s ease; /* Add a smooth transformation on hover */
  }
  
  .arrow-icon:hover {
    transform: translateX(10px); /* Move the icon 10 pixels to the right on hover */
  }
.hover-container:hover .arrow-icon {
  transform: translateX(10px); /* Move the icon 10 pixels to the right on hover */
}  
/* Footer.css */
@media screen and (max-width: 426px) {
    .footer{
        padding: 0px 0px;
    }
    
}