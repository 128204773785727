.nav{
    position: fixed;
    top:0px;
    display: block;
    z-index: 1;
    background: rgba(255,255,255,0.8);
    backdrop-filter: blur(10px);
    padding: 20px 40px;
    height: 120px;
}
.logo{
    width: 140px;
    height: 60px;
    top:27px;
    z-index: 1;
    position: fixed;
}
.navmenu{
    color: #000;
    text-decoration: none;
    padding: 20px;
    letter-spacing: 1.28px;
    
}
.navmenu:hover{
    color:#FF2526;
}
.navmenu.active {
    color: #ff0000;
    font-weight: 600;
  }


@media screen and (min-width:375px) and (max-width:840px) {
    .logo{
        margin-left: -15px;
        width: 120px;
    }
    
}