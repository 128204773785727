.abouthome{
    margin-top: 100px;
    padding: 180px;
    background-color:#F5F5F5;
    margin-bottom: 100px;
    gap: 75px;
  }
  /* .custom-padding-left-inherit {
    padding-left: inherit;
  } */
  .leftlayout{
    width: auto;
    height: auto;
    /* padding-left: 266px; */
    float: right;
    text-align: left;
  }
  .lefttext{
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 400px;
  }
  .righttext{
    color: #000;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    text-align: left;
  }
 
 
  @media screen and (max-width:1024px) {
  .abouthome{
    padding: 30px;
    gap:0;
  }
  
}
  @media screen and (max-width:768px){
    .abouthome{
        padding: 160px 10px;
        gap:20px;
        margin-top: 50px;
        margin-bottom: 60px;
    }
    .leftlayout{
        padding-left: 7px;
    } 
}
@media screen and (max-width:426px){
    .abouthome{
        padding: 60px 10px;
        margin-top:100px;
    } 
    .righttext{
        padding: 0px 0px 0px 20px;
    }
}