*{
  padding: 0;
  margin: 0;
  text-decoration: none;
}
.font-face-b {
  font-family: "ClashGroteskBold";
}
.font-face-el {
  font-family: "ClashGroteskExtralight";
}
.font-face-l {
  font-family: "ClashGroteskLight";
}
.font-face-m {
  font-family: "ClashGroteskMedium";
}
.font-face-r {
  font-family: "ClashGroteskRegular";
}
.font-face-sb {
  font-family: "ClashGroteskSemibold";
}
.wedoimg{
  width: 60px;
  height: 60px;
}
.sublay{
  width: 751px;
  gap:120px;
  padding-bottom: 100px;
}
@media screen and (max-width:768px){
  .sublay{
      padding:30px 60px;
  }
}
@media screen and (max-width:426px){
  .sublay{
      flex-direction: column;
      padding: 35px;
      gap:40px;
  }
}
