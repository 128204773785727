.Contactdetails {
    position: absolute;
    top: 10%;
    font-family: Montserrat;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;
}

.regadd {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.over-img-contacttext {
    color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.over-img-addresstex1 {
    color: white;
    font-size: 32px;
}

.over-img-contacttext2 {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    line-height: normal;
}


@media screen and (min-width:989px) and (max-width:1200px) {
    .Contactdetails {
        top: 5%;
        gap: 10px;
    }

    hr {
        margin: 0.5rem;
    }
}

@media screen and (min-width:860px) and (max-width:989px) {
    .Contactdetails {
        top: 2.5%;
        gap: 10px;
    }

    hr {
        margin: 0.5rem;
    }

    .over-img-contacttext {
        font-size: xx-large;
    }

    .over-img-addresstex1 {
        font-size: x-large;
    }

    .over-img-addresstex2 {
        font-size: x-large;
    }
}

@media screen and (min-width:571px) and (max-width:860px) {
    .over-img-contacttext {
        font-size: 32px;
    }

    .over-img-addresstex1 {
        font-size: 24px;
    }

    .over-img-contacttext2 {
        font-size: 22px;
    }

    hr {
        margin: 0.5px;
    }

    .Contactdetails {
        top: 5%;
        gap: 10px;
    }
}

@media screen and (min-width:571px) and (max-width:760px) {
    .Contactdetails {
        top: 2%;
        gap: 5px;
    }

    .over-img-contacttext2 {
        font-size: 16px;
    }

    .over-img-addresstex1 {
        font-size: 20px;
    }

    .regadd {
        gap: 0px;
    }
}
@media screen and (max-width:571px) {
    .over-img-contacttext {
        font-size: larger;
    }

    .over-img-addresstex1 {
        font-size: large;
    }

    .over-img-contacttext2 {
        font-size: x-small;
    }
    hr {
        margin: 0.5px;
    }
    .Contactdetails {
        top: 5%;
        gap: 3px;
    }
}
@media screen and (max-width:430px) {
    .Contactdetails {
        top: 5%;
        gap: 2px;
    }

}

@media screen and (min-width:377px) and (max-width:405px) {

    .Contactdetails {
        top: 0%;
        gap: 0px;
    }
}

@media screen and (max-width:376px) {
    hr {
        margin: 0.5px;
    }

    .over-img-contacttext {
        font-size: medium;
    }

    .over-img-addresstex1 {
        font-size: small;
    }

    .over-img-contacttext2 {
        font-size: xx-small;
    }

    .Contactdetails {
        top: 2%;
        gap: 2px;
    }

    .regadd {
        gap: 0px;
    }
}

@media screen and (max-width:320px) {
    .over-img-contacttext {
        font-size: medium;
    }

}