.over-img{
    width: 100%;
    /* align-items: center; */
    border-radius: 20px; 
    /* top:20px; */
    border-color:lightgrey;
    border-style:solid ;
    border-width: 1px;
}
.learn-layout{
    padding: 50px 150px;
  }
  .flow-layout{
    position: absolute;
    top: 35%;
    left:15%;
  }
  .learn-layout-text1{
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-bottom: 15px;
  }
  .learn-layout-text2{
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
    padding-right: 50%;
    margin-bottom: 15px;
  }
@media screen and (min-width:1025px) and (max-width:1440px){
    .learn-layout{
        padding: 60px 90px;
    }
    .flow-layout{
        left:13%;
        top:30%

    }
    .over-img{
        height: 75vh;
    }
}
@media screen and (min-width:769px) and (max-width:1024px){
    .learn-layout{
        padding: 60px 40px;
    }
    .flow-layout{
        left:14%;
        top:27%
    }
    .over-img{
        height: 60vh;
    }
}
@media screen and (min-width:427px) and (max-width:768px){
    .learn-layout{
        padding: 60px 15px;
    }
    .flow-layout{
        top:25%;
        left:10%
    }
    .over-img{
        height: 55vh;
    }
    
}
@media screen and (min-width:320px) and (max-width:426px){
    .learn-layout{
        padding: 55px 15px;
    }
    .flow-layout{
        top:25%;
        left:10%
    }
    .learn-layout-text1{
        font-size: 17px;
    }
    .learn-layout-text2{
        font-size: 12px;
    }
    .learn-layout-button{
        font-size: small;
    }
    .over-img{
        height: 45vh;
    }
}