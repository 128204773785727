.learnsteplayout{
    padding: 80px 0px;
    gap: 56px;
  }
  .learnsteplayout-left{
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding-left: 300px;
  }
  .learnsteplayout-lefttext1{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.28px;
    opacity: 0.5;
  }
  .learnsteplayout-lefttext2{
    color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.64px;
  }
  .learnsteplayout-right{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
    justify-content: left;
  }
  .learnsteplayout-rightlayout1{
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
  }
  .learnsteplayout-rightlayout2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 50%;
  }
  .learnsteplayout-rightlayout2text1{
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
  }
  .learnsteplayout-rightlayout2text2{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  }
  @media screen and (min-width:1025px) and (max-width:1440px){
    .learnsteplayout-left{
      padding-left: 300px;
  }
  .learnsteplayout-rightlayout2{
      width: 50%;
  }
}
@media screen and (min-width:769px) and (max-width:1024px){
  .learnsteplayout-left{
      padding-left: 100px;
  }
  .learnsteplayout-rightlayout2{
      width: 50%;
  }
}
@media screen and (min-width:427px) and (max-width:768px){
  .learnsteplayout-left{
      padding-left: 50px;
  }
  .learnsteplayout-rightlayout2{
      width: 70%;
  }
}
@media screen and  (max-width:520px) {
  .learnsteplayout{
    flex-direction: column;
  }
  .learnsteplayout-right{
    padding: 0px 10px;
  }
}
@media screen and (min-width:320px) and (max-width:426px){
  .learnsteplayout{
    flex-direction: column;
  }
  .learnsteplayout-left{
      padding-left: 30px;
  }
  .learnsteplayout-rightlayout2{
      width: 70%;
  }
  .learnsteplayout-right{
    padding-left: 30px;
}
}
@media screen and (min-width:320px) and (max-width:365px){
  .learnsteplayout-rightlayout2text1{
    font-size: 20px;
  }
}