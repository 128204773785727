.bodytext-layout{
    width: 99%;
    height: 200px;
    text-align: center;
  }
  .text1{
    color:gray;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.28px;
  }
  .text2{
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
    padding-left: 425px;
    padding-right: 425px;
  
  }

  @media screen and (max-width:1180px){
    .text2{
        padding-left: 200px;
        padding-right: 190px;
    }   
    
}
@media screen and (max-width:768px){
    .text2 {
        padding-left: 100px;
        padding-right: 100px;
    }
}
@media screen and (max-width:426px){
    .text2{
        padding-left: 10px;
        padding-right: 1px;
    }
}